<template lang="pug">
  .competitor-prices
    Filters(
      :current-plan="currentPlan"
      :filters="filters"
      :otas="allowedOtas"
      :shops="shopsItems"
      :car-classes="carClassesItems"
      :shops-loading="shopsItemsLoading"
      :car-classes-loading="carClassesItemsLoading"
      :insurance-list="insuranceList"
      :time-list="timeList"
      :departure-date="departureDate"
      @change-ota="handleChangeOta"
      @change-shop="handleChangeShop"
      @change-car-class="handleChangeCarClass"
      @change-insurance="handleChangeInsurance"
      @change-time="handleChangeTime"
      @change-departure-date="handleChangeDepartureDate"
    )
    .position-relative
      AppOverlayLoader(:state="competitorsLoading")
      CalendarHeader.sticky(
        :date-range="dateRange"
      )
      Calendar(
        v-if="!isEmpty(competitors)"
        :competitors="competitors"
        :rental-periods="rentalPeriods"
        :date-range="dateRange"
      )
</template>

<script>
  import { getDateRange } from "@/helpers/dates.js"

  // store modules
  import shopMatchingModule from "@/config/store/rate_check_mode/shops"
  import pricesCarClassesModule from "@/config/store/rate_check_mode/car_classes"
  import competitorPricesModule from "@/config/store/rate_check_mode/prices"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withAllowedOtaList from "@/mixins/withAllowedOtaList"

  // temporary
  import { i18n } from "@/config"
  import {
    RP_DAILY_1_DAY,
    RP_DAILY_OVERNIGHT,
    RP_DAILY_2_DAYS,
    RP_DAILY_3_DAYS,
    RP_DAILY_LATE,
    RP_HOURLY_1_6,
    RP_HOURLY_7_12,
    RP_HOURLY_13_24,
    RP_HOURLY_LATE,
    RP_HOURLY_LATE_DAILY
  } from "@/config/constants"
  import { map, isEmpty } from "lodash-es"
  import { INSURANCE_LIST, TIME_SLOT_LIST } from "@/config/store/price_management/index.js"

  const RENTAL_PERIOD_LIST_DAILY = map(
    [RP_DAILY_1_DAY, RP_DAILY_OVERNIGHT, RP_DAILY_2_DAYS, RP_DAILY_3_DAYS, RP_DAILY_LATE],
    value => ({
      title: i18n.t(`price_calendar.filters.rental_periods.daily.${value}`),
      value
    })
  )

  const RENTAL_PERIOD_LIST_HOURLY = map(
    [RP_HOURLY_1_6, RP_HOURLY_7_12, RP_HOURLY_13_24, RP_HOURLY_LATE, RP_HOURLY_LATE_DAILY],
    value => ({
      title: i18n.t(`price_calendar.filters.rental_periods.hourly.${value}`),
      value
    })
  )

  const competitorsPricesMixin = withStoreModule(competitorPricesModule, {
    name: "competitorsPrices",
    readers: {
      currentPlan: "currentPlan",
      competitors: "items",
      competitorsLoading: "loading",
      filters: "filters"
    },
    mutations: {
      setFilters: "SET_FILTERS",
      setCompetitors: "SET_ITEMS"
    },
    actions: {
      fetchCompetitors: "FETCH_ITEMS"
    }
  })

  const shopMatchingMixin = withStoreModule(shopMatchingModule, {
    name: "competitorsPricesShops",
    readers: {
      shopsItems: "items",
      shopsItemsLoading: "loading"
    },
    actions: { fetchShopsItems: "FETCH_ITEMS" }
  })

  const pricesCarClassesMixin = withStoreModule(pricesCarClassesModule, {
    name: "competitorsPricesCarClasses",
    readers: {
      carClassesItems: "items",
      carClassesItemsLoading: "loading"
    },
    actions: { fetchCarClassesItems: "FETCH_ITEMS" }
  })

  export default {
    components: {
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      Filters: () => import("./Filters"),
      CalendarHeader: () => import("@/pages/PriceManagement/PriceCalendar/Calendar/CalendarHeader"),
      Calendar: () => import("./Calendar")
    },

    mixins: [shopMatchingMixin, pricesCarClassesMixin, competitorsPricesMixin, withAllowedOtaList],

    data() {
      return {
        insuranceList: INSURANCE_LIST,
        timeList: TIME_SLOT_LIST,
        departureDate: []
      }
    },

    computed: {
      dateRange({ departureDate }) {
        if (isEmpty(departureDate)) return []

        return getDateRange(...departureDate)
      },

      isValidFilters({ filters: { ota, shop, carClass } }) {
        return !isEmpty(ota) && !isEmpty(shop) && !isEmpty(carClass)
      },

      rentalPeriods({
        filters: {
          time: { title }
        }
      }) {
        return title === "daily" ? RENTAL_PERIOD_LIST_DAILY : RENTAL_PERIOD_LIST_HOURLY
      }
    },

    methods: {
      isEmpty,

      fetchShops() {
        return this.fetchShopsItems(this.filters)
      },

      fetchCarClasses() {
        this.fetchCarClassesItems(this.filters)
      },

      handleChangeOta(ota) {
        this.setFiltersWithResetCompetitors(this.fetchShops, { ota, shop: {}, carClass: {} })
      },

      handleChangeShop(shop) {
        this.setFiltersWithResetCompetitors(this.fetchCarClasses, { shop, carClass: {} })
      },

      handleChangeCarClass(carClass) {
        this.setFiltersWithCompetitorsFetching({ carClass })
      },

      handleChangeInsurance(insurance) {
        this.setFiltersWithCompetitorsFetching({ insurance })
      },

      handleChangeTime(time) {
        this.setFiltersWithCompetitorsFetching({ time })
      },

      handleChangeDepartureDate(departureDate) {
        this.departureDate = departureDate
      },

      setFiltersWithResetCompetitors(callback, filters) {
        this.setFilters(filters)
        this.setCompetitors([])

        callback()
      },

      async setFiltersWithCompetitorsFetching(filters) {
        this.setFilters(filters)

        if (this.isValidFilters) {
          await this.fetchCompetitors()
          this.setDepartureDateByCurrentPlan()
        }
      },

      setDepartureDateByCurrentPlan() {
        this.departureDate = [new Date(this.currentPlan.open_start_date), new Date(this.currentPlan.open_end_date)]
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .competitor-prices
    .sticky
      position: sticky
      top: 0
      z-index: 10
      background: $default-white
      max-width: 100%
      padding-top: 10px
      width: fit-content
</style>

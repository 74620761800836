import { values, includes } from "lodash-es"

import { PRICE_MANAGEMENT_ALLOWED_OTA_IDS } from "@/config/constants"

export default {
  computed: {
    allowedOtas() {
      const allowedOtaIds = values(PRICE_MANAGEMENT_ALLOWED_OTA_IDS)

      return this.$store.getters.translatedOtaList.filter(({ id }) => includes(allowedOtaIds, id))
    }
  }
}

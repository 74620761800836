import { api } from "@/config"
import { mapFilters } from "@/config/store/price_management/actions.js"

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit, state: { filters } }) => {
    const params = { filters: { ...mapFilters(filters), car_class_ids: [filters.carClass.id] } }
    const response = await api.get(baseURI, { params })

    const { competitors, plan } = response.data.data

    commit("SET_CURRENT_PLAN", plan)
    commit("SET_ITEMS", competitors)
  }
})

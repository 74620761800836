import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"
import extractActions from "./actions"
import extractMutations from "./mutations"

import { INSURANCE_LIST, TIME_SLOT_LIST } from "@/config/store/price_management/index"

const BASE_URI = "/rate_check_mode/competitors_prices"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  withFilters: {
    ota: {},
    shop: {},
    carClass: {},
    insurance: INSURANCE_LIST[1],
    time: TIME_SLOT_LIST[1]
  }
})

store.mergeState({
  currentPlan: {}
})

const { FETCH_ITEMS } = extractActions({ baseURI: BASE_URI })
store.mergeActions({ FETCH_ITEMS }, withLoading)

store.mergeMutations(extractMutations())

export default store

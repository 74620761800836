import { api } from "@/config"
import { isWithinInterval } from "date-fns"
import { isNil, get, transform, isEmpty } from "lodash-es"
import { handleFailedResponse } from "@/helpers/common"

export const mapFilters = filters => ({
  ota_id: filters.ota.id,
  shop_id: filters.shop.id,
  car_class_id: filters.carClass.id,
  insurance: filters.insurance.title,
  time: filters.time.title
})

// response data structure:
// {
//   id: 1,
//   name: "Car class 1",
//   insurance: "included",
//   inventories: {
//     "2021/09/02": {
//       total: 2,
//       booked: 1
//     },
//     "2021/09/03": {
//       total: 6,
//       booked: 4
//     }
//   },
//   prices: {
//     day1: {
//       "2021/09/02": 700,
//       "2021/09/03": 700
//     },
//     overnight: {
//       "2021/09/02": 800,
//       "2021/09/03": 800
//     },
//     days2: {
//       "2021/09/02": 900,
//       "2021/09/03": 900
//     },
//     days3: {
//       "2021/09/02": 1000,
//       "2021/09/03": 1000
//     },
//     late: {
//       "2021/09/02": 1100,
//       "2021/09/03": 1100
//     }
//   }
// }

const extractChangesForUpdate = (plan, changes, range, useRecommendedPrices) => {
  const recommendedPrices = plan.recommended_prices?.prices || {}
  const [start, end] = range

  return transform(plan.prices, (byPeriodAcc, pricesByDays, rentalPeriod) => {
    const changesForRentalPeriod = transform(pricesByDays, (byDaysAcc, currentValue, date) => {
      const manualValue = get(changes, `${rentalPeriod}.${date}`)
      const recommendedValue = get(recommendedPrices, `${rentalPeriod}.${date}`)
      const result = useRecommendedPrices ? manualValue || recommendedValue : manualValue

      if (!isNil(result) && result !== currentValue.applied_value && isWithinInterval(new Date(date), { start, end })) {
        byDaysAcc[date] = result
      }
      return byDaysAcc
    })

    if (!isEmpty(changesForRentalPeriod)) {
      byPeriodAcc[rentalPeriod] = changesForRentalPeriod
    }

    return byPeriodAcc
  })
}

export default ({ baseURI }) => ({
  async FETCH_ITEM({ commit, state }) {
    try {
      const response = await api.get(baseURI, { params: { filters: mapFilters(state.filters) } })
      commit("SET_ITEM", response.data.data)
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  async UPDATE_ITEM(
    { commit, state: { item: plan, filters } },
    { changes, departureDate, isApplyRecommendedPrices = false }
  ) {
    try {
      const params = {
        filters: mapFilters(filters),
        prices: extractChangesForUpdate(plan, changes, departureDate, isApplyRecommendedPrices)
      }

      const response = await api.put(baseURI, params)
      commit("SET_ITEM", response.data.data)
    } catch (e) {
      handleFailedResponse(e)
    }
  }
})

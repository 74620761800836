import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractActions from "./actions"

const BASE_URI = "/rate_check_mode/shops"

const store = new StoreItemsModule({
  baseURI: BASE_URI
})

const { FETCH_ITEMS } = extractActions({ baseURI: BASE_URI })
store.mergeActions({ FETCH_ITEMS }, withLoading)

export default store

import { api } from "@/config"

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit }, { ota }) => {
    const params = {
      pagination: { _disabled: true },
      ota_id: ota.id
    }
    const response = await api.get(baseURI, { params })

    commit("SET_ITEMS", response.data.data)
  }
})

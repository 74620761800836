import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractActions from "./actions"
import extractMutations from "./mutations"

// misc
import { last, map } from "lodash-es"
import { i18n } from "@/config"
import { RP_1_DAY, RP_OVERNIGHT, RP_2_DAYS, RP_3_DAYS, RP_LATE } from "@/config/constants"

const BASE_URI = "/prices"

const RENTAL_PERIOD_LIST = map([RP_1_DAY, RP_OVERNIGHT, RP_2_DAYS, RP_3_DAYS, RP_LATE], value => ({
  title: i18n.t(`price_calendar.filters.rental_periods.${value}`),
  value
}))
export const INSURANCE_LIST = [{ title: "included" }, { title: "optional" }]
export const TIME_SLOT_LIST = [{ title: "hourly" }, { title: "daily" }]

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  withPagination: true,
  withFilters: {
    ota: {},
    shop: {},
    carClass: {},
    insurance: last(INSURANCE_LIST),
    time: last(TIME_SLOT_LIST)
  }
})

store.mergeState({
  item: {},
  rentalPeriodList: RENTAL_PERIOD_LIST,
  insuranceList: INSURANCE_LIST,
  timeSlotList: TIME_SLOT_LIST
})

const { FETCH_ITEM, UPDATE_ITEM } = extractActions({ baseURI: BASE_URI })

store.mergeActions({ FETCH_ITEM, UPDATE_ITEM }, withLoading)
store.mergeMutations(extractMutations())

export default store
